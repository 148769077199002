@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("styles/fonts.css");

html,
body,
:root {
  margin: 0;
  padding: 0;
  --plyr-color-main: #f40f3a;
}

::selection {
  background: var(--plyr-color-main);
  color: #fff;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  /* Firefox */
  image-rendering: -moz-crisp-edges;
  /* Opera */
  image-rendering: -o-crisp-edges;
  /* Webkit (non-standard naming) */
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  /* IE (non-standard property) */
  -ms-interpolation-mode: nearest-neighbor;
}
